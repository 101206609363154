export const CanvasEvents = {
    ADD_TEXT_BOX: 'addTextBox',
    ADD_CARD: 'addCard',
    ADD_IMAGE: 'addImage',
    ADD_LINK_TO_ITEM: 'addLinkToItem',
    COPY_CARDS: 'copyCards',
    DELETE_CARD: 'deleteCard',
    DELETE_CARDS: 'deleteCards',
    DUPLICATE_SELECTED: 'duplicateSelected',
    GROUP_SELECTED: 'groupSelected',
    UNGROUP_SELECTED: 'ungroupSelected',
    UNSELECT_SELECTED: 'unselectSelected',
    REMOVE_SELECTED: 'removeSelected',
    PREV_ITEM: 'prevItem',
    NEXT_ITEM: 'nextItem',
    SAVE_CARD: 'saveCard',
    SEND_SELECTED_BACKWARDS: 'sendSelectedBackwards',
    BRING_SELECTED_FORWARD: 'bringSelectedForward',
    MAKE_BIGGER: 'makeBigger',
    MAKE_SMALLER: 'makeSmaller',
    SHOW_CARD_HISTORY: 'showCardHistory',
    TOGGLE_LOCK: 'toggleLock',
    SET_INTERACTION_MODE: 'setInteractionMode',
    SET_IS_DRAWING_MODE: 'setDrawMode',
    SET_IS_PAINT_BUCKET_MODE: 'setPaintBucketMode',
    SET_PAINT_BUCKET_SETTINGS: 'setPaintBucketSettings',
    SET_IS_DRAW_SHAPE_MODE: 'setDrawShapeMode',
    SET_DRAW_SHAPE_SETTINGS: 'setDrawShapeSettings',
    UPDATE_SHAPE_SETTINGS: 'updateShapeSettings',
    UPDATE_TEXT_SETTINGS: 'updateTextSettings',
    UPDATE_DRAW_SETTINGS: 'updateDrawSettings',
    UPDATE_TEXT_SETTINGS_WHOLE: 'updateTextSettingsWhole',
    UPDATE_ERASER_SETTINGS: 'updateEraserSettings',
    INCREMENT_DECK: 'incrementDeck',
    DECREMENT_DECK: 'decrementDeck',
};
function createEventFunctions(eventName, validator = null) {
    return {
        emit: function (detail = {}, ctx) {
            if (validator && !validator(detail)) {
                console.error(`Invalid detail for event ${eventName}:`, detail);
                return;
            }
            ctx.dispatchEvent(new CustomEvent(eventName, { detail, bubbles: true, composed: true }));
        },
        listen: function (handler, ctx) {
            const wrappedHandler = (event) => {
                if (validator && !validator(event.detail)) {
                    console.error(`Invalid detail received for event ${eventName}:`, event.detail);
                    return;
                }
                handler(event);
            };
            ctx.addEventListener(eventName, wrappedHandler);
            return () => ctx.removeEventListener(eventName, wrappedHandler);
        },
    };
}
// Validator functions
const cardIdsValidator = (detail) => {
    return Array.isArray(detail.cardIds) && detail.cardIds.every((id) => typeof id === 'string');
};
const CanvasEventFunctions = Object.fromEntries(Object.entries(CanvasEvents).map(([key, value]) => {
    let validator = null;
    if (key === 'COPY_CARDS' || key === 'DELETE_CARDS') {
        validator = cardIdsValidator;
    }
    return [key, createEventFunctions(value, validator)];
}));
export default CanvasEventFunctions;
// Example usage:
// const remover = CanvasEventFunctions.COPY_CARDS.listen(ctx, handler)
// CanvasEventFunctions.COPY_CARDS.emit(ctx, { cardIds: ['1', '2', '3'] }) // Valid
// CanvasEventFunctions.COPY_CARDS.emit(ctx, { cardIds: [1, 2, 3] }) // Invalid, will log error
// CanvasEventFunctions.ADD_TEXT_BOX.emit(ctx, { someData: 'value' }) // No validator, will pass through
